export default [
  {
    imgSrc: '/images/home/philosophy/ic-bulb.png',
    imgAlt: 'ic-bulb',
    desc: 'We are pioneers, building early- stage startups for the last decade. We apply this knowledge and expertise to identify and partner with the next generation of global leading businesses.',
  },
  {
    imgSrc: '/images/home/philosophy/ic-compass.png',
    imgAlt: 'ic-compass',
    desc: 'As a dominate investor in the market, we get access to some of the best opportunities and focus on scaling the region’s startups into the next 100B+ platform.',
  },
  {
    imgSrc: '/images/home/philosophy/ic-award.png',
    imgAlt: 'ic-award',
    desc: 'We have extensive experience in operating within the challenging early stage environment - we know more and do better than anyone else.',
  },
];
