export default [
  {
    date: '2020-11-24',
    desc: '富盈數據攜手多家台灣新創　共組「網紅IP製造大聯盟」',
    link: 'https://finance.ettoday.net/news/1861806',
  },
  {
    date: '2020-11-08',
    desc: 'GliaCloud推出虛擬角色影音製作方案 無痛創作專屬IP角色',
    link: 'https://www.techbang.com/posts/82290-gliacloud-virtual-character-video-and-audio-production-program',
  },
  {
    date: '2020-08-06',
    desc: '2020胡潤全球獨角獸榜出爐 蘇寧體育二度上榜',
    link: 'http://big5.xinhuanet.com/gate/big5/www.xinhuanet.com/tech/2020-08/06/c_1126334275.htm',
  },
];
