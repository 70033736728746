import styled from 'styled-components';

import { HomeInner } from '../../../global/styled';

export const Wrapper = styled(HomeInner)`
  padding-bottom: 80px;
`;
export const CardGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  a {
    color: #000000;
  }
`;
export const Btn = styled.div`
  border: 2px solid #000000;
  padding: 10px 30px;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  transition: .3s;
  color: #000000;
  font-weight: bold;
  :hover {
    background: #000000;
    color: #fff;
  }
`;
