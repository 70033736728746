export default [
  {
    title: 'Early Investors,<br /> <span>Long-Term Partners</span>',
    desc: 'We believe that early-stage investment is the core value of venture capital. Through strategic allocation of startup capital, we can truly nurture potential startups to deliver our investment partners with superior returns.',
    img: '/images/home/banner-01.png',
  },
  {
    title: '<span>Differentiated Investment Approach</span><br /> Which Truly Creates Value',
    desc: 'We are not only investing; we will also be part of your team working with you to scale your business. Leveraging our business experience, and through our network of business partners, we ensure the greatest opportunities to success.',
    img: '/images/home/banner-02.png',
  },
  {
    title: 'We Take Risk on<br /> <span>Brilliant Team</span>',
    desc: 'Each of our investments is strictly-picked and highly selective, and we focus on helping the best technology startups grow into large, dominant category-defining companies by investing and supporting them in their earliest growth stage.',
    img: '/images/home/banner-03.png',
  },
];
