import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 850px;
  margin: auto;
  padding: 60px 0 0;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }
  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    padding: 60px 20px 0;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;
  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-bottom: 30px;
  }
`;
export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  span {
    color: #fff;
    text-align: center;
  }
`;
export const Card = styled.div`
  border: 5px solid #fff;
  width: 200px;
  height: 200px;
  margin: 20px 20px 10px;
  position: relative;
  p {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    width: fit-content;
    height: fit-content;

    color: #fff;
    span {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 180px;
    height: 180px;
    p {
      span {
        font-size: 22px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100px;
    height: 100px;
    p {
      span {
        font-size: 16px;
      }
    }
  }
`;
export const Btn = styled.div`
  border: 2px solid #fff;
  background: transparent;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  transition: .3s;
  :hover {
    background: #fff;
    color: black;
  }
`;
export const StaticWrapper = styled.div`
  max-width: 615px;
  margin: auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
  img {
    height: auto;
    width: 80%;
    margin: 50px 0 100px;
  }
`;