import React from 'react';
import { Link } from "react-router-dom";

import data from './data';

import {
  Outer, Menus, MenuTxt,
} from './styled';

const MobileMenu = ({
  isCollapse, activeId, handClick
}) => (
  <Outer isCollapse={isCollapse}>
    <Menus isMobile>
      { data.menus.map((menu, idx) => (
        <Link
          key={idx}
          to={menu.link}
          onClick={() => handClick(idx)}
        >
          <MenuTxt isActive={idx === activeId}>
            {menu.text}
            <span />
          </MenuTxt>
        </Link>
      ))}
    </Menus>
  </Outer>
);

export default MobileMenu;
