import React, { useState, useEffect, useCallback } from 'react';

import data from './data';

import { HomeH1, HomeP1 } from '../../global/styled';
import {
  Wrapper, Icon, Outer, Inner, Content, Group, Circle,
} from './styled';

const CircleGroup = ({ activeId, handClick }) => (
  <Group>
    { data.map((circle, idx) => (
      <Circle onClick={() => handClick(idx)} isActive={activeId === idx} key={idx} />
    ))}
  </Group>
);

const Carousel = () => {
  const [activeId, setId] = useState(0);

  const handleActiveId = useCallback(event => {
    if (event > -1 && event < 3) {
      setId(event);
    }
  }, []);

  useEffect(() => {
    let start = null;

    window.addEventListener('touchstart', function(e) {
      start = e.changedTouches[0];
    });

    window.addEventListener('touchend', function(e) {
      let end = e.changedTouches[0];

      if(end.screenX - start.screenX > 0) {
        handleActiveId(activeId - 1);
      } else if(end.screenX - start.screenX < 0) {
        handleActiveId(activeId + 1);
      }
    });
  }, [activeId]);

  return (
    <Wrapper id="swipe-sections">
      <Icon src="/icons/ic-arrow.png" onClick={() => handleActiveId(activeId - 1)} />
      <Outer distance={-activeId*100}>
        { data.map((context, idx) => (
          <Inner image={context.img} key={idx}>
            <Content key={idx}>
              <HomeH1 dangerouslySetInnerHTML={{ __html: context.title }} />
              <HomeP1>{context.desc}</HomeP1>
            </Content>
          </Inner>
        ))}
      </Outer>
      <Icon src="/icons/ic-arrow.png" isReverse onClick={() => handleActiveId(activeId + 1)} />
      <CircleGroup activeId={activeId} handClick={handleActiveId} />
    </Wrapper>
  );
};

export default Carousel;
