import React from 'react';

import { Outer } from './styled';

const Sector = ({ data }) => (
  <Outer>
    <img {...data.img} />
    <p>{data.text}</p>
  </Outer>
);

export default Sector;
