import React from 'react';

import { H2, P2, P3 } from '../../../global/styled';
import {
  Wrapper, Inner, Containers, TextContainer, Vendors,
} from './styled';

import data from './data';

const Notable = () => (
  <Wrapper>
    <Inner>
      <H2>Notable Transaction</H2>
      <Containers>
        <TextContainer>
          <P2>{data.desc}</P2>
          <P3 dangerouslySetInnerHTML={{ __html: data.avarage}} />
        </TextContainer>
        <Vendors>
          { data.vendors.map((vendor, idx) => (
            <img src={vendor} key={idx} alt={`vendor-${idx}`} />
          ))}
        </Vendors>
      </Containers>
    </Inner>
  </Wrapper>
);

export default Notable;
