import React from 'react';

import Philosophy from './Philosophy';
import Fund from './Fund';

import { Wrapper } from './styled';

const UpperBanner = () => (
  <Wrapper>
    <Philosophy />
    <Fund />   
  </Wrapper>
);

export default UpperBanner;
