import React from 'react';

import { Wrapper, Inner } from './styled';

const Footer = () => (
  <Wrapper>
    <Inner>
      <img src="/images/logo-white.png"  alt="ic-logo" />
      <p>Email : info@orion-vc.com</p>
    </Inner>
  </Wrapper>
);

export default Footer;
