import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
`;

export const BGGray = styled.div`
  @media screen and (max-width: 1024px) {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
  }
`;
export const Outer = styled.div`
  background: #ffffff;
  position: absolute;
  bottom: -90%;

  min-height: 150px;
  max-width: 680px;
  width: max-content;
  padding: 0 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(176, 176, 176, 0.4);

  display: flex;
  z-index: 2;

  animation: ${fadeIn} .3s;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    bottom: 0;
    top: 50%;
    height: max-content;
    max-width: 280px;
    padding: 20px 10px 10px;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    max-height: 70vh;
    overflow: scroll;
  }
`;
export const Close = styled.div`
  display: none;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    display: flex;

    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  ${({ isIntro }) => isIntro && css`
    justify-content: center;
  `}
  @media screen and (max-width: 1024px) {
    margin: 0 0 20px;
  }
`;
export const Logo = styled.img`
  width: 150px;
  @media screen and (max-width: 1024px) {
    width: 100px;
  }
  @media screen and (max-width: 767px) {
    width: 150px;
  }
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Item = styled.div`
  display: flex;
  align-items: center;

  margin: 2px 0;

  p {
    font-size: 16px;
    margin: 0 2px;
    font-weight: lighter;
    color: #333333;
    letter-spacing: 0.5px;
  }
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  span {
    font-size: 12px;
    font-weight: lighter;
    color: #a5a5a5;
  }
  ${({ isInfo }) => isInfo && css`
    width: max-content;
    p {
      font-size: 12px;
    }
  `}
  @media screen and (max-width: 1024px) {
    p, span {
      font-size: 12px;
    }
  }
`;
export const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin: 8px 8px 8px 0;
`;
