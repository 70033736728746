import React from 'react';
import { Link } from 'react-router-dom';

import data from './data';

import { H2, P2, Span } from '../../../global/styled';
import {
  Wrapper, CardWrapper, Outer, Card, Btn, StaticWrapper,
} from './styled';

const Fund = () => (
  <Wrapper>
    <H2>The Fund</H2>
    <CardWrapper>
      { data.fund.map((card, idx) => (
        <Outer key={idx}>
          <Card>
            <P2 dangerouslySetInnerHTML={{ __html: card.number }} />
          </Card>
          <Span>{card.text}</Span>
        </Outer>
      ))}
    </CardWrapper>
    <Link to="/fund">
      <Btn>Read More</Btn>
    </Link>
    <StaticWrapper>
      <H2>The Statistics</H2>
      <P2>{data.statics.desc}</P2>
      <img src={data.statics.graph} alt="img-static" />
    </StaticWrapper>
  </Wrapper>
);

export default Fund;
