import styled, { css } from 'styled-components';

const colors = {
  red: '#a73e29',
  gray: '#333333',
  transparent: 'transparent',
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
export const Outer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  margin: 20px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 40px 40px;
    align-items: center;
    max-width: 280px;
  }
`;
export const Logo = styled.img`
  width: 150px;
  @media screen and (max-width: 1024px) {
    width: 100px;
  }
  @media screen and (max-width: 767px) {
    width: 150px;
  }
`;
export const Blank = styled.div`
  position: absolute;
  bottom: 18px;
  width: calc(100% - 60px);
  color: #ffffff;

  ${({ color }) => color && css`
    background: ${colors[color]};
  `};
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
`;