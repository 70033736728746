import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;
export const Icon = styled.img`
  position: absolute;
  z-index: 99;
  left: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  ${({ isReverse }) => isReverse && css`
    transform: rotate(180deg);
    left: unset;
    right: 40px;
  `}
  @media screen and (max-width: 1024px) {
    left: 20px;
    ${({ isReverse }) => isReverse && css`
    left: unset;
    right: 20px;
  `}
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const Outer = styled.div`
  display: flex;
  overflow: hidden;
  width: fit-content;
  transition: .5s;
  margin-left: 0;
  ${({ distance }) => distance && css`
    margin-left: ${distance}vw; 
  `}
`;
export const Inner = styled.div`
  position: relative;
  width: 100vw;
  height: 95vh;
  max-height: 960px;
  ${({ image }) => image && css`
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;
export const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 65vw;
  height: fit-content;
  padding: 60px 60px 40px 300px;
  background: rgba(29, 51, 18, .8);
  h1 {
    color: #fff;
    max-width: 980px;
    span {
      background: linear-gradient(to top, rgba(194, 188, 18, .7) 30%, transparent 0);
    }
  }
  p {
    color: #fff;
    max-width: 850px;
    font-weight: lighter;
  }
  @media screen and (max-width: 1440px) {
    padding: 60px 60px 40px 210px;
  }
  @media screen and (max-width: 1024px) {
    padding: 60px 60px 40px 130px;
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    height: max-content;
    margin: auto;
    left: 0;
    right: 0;
    padding: 30px 15px 50px;
  }
`;
export const Group = styled.div`
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  display: flex;
`;
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  border-radius: 15px;
  margin: 6px;
  background: ${({ isActive }) => isActive ? '#fff' : 'transparent'};
  transition: .3s;
`;
