import React, { useEffect } from 'react';

import Header from '../Header';
import Carousel from '../Carousel';
import UpperBanner from '../UpperBanner';
import MidBanner from '../MidBanner';
import News from '../News';
import Footer from '../Footer';

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Carousel />
      <UpperBanner />
      <MidBanner />
      <News />
      <Footer />
    </>
  );
};

export default App;
