import React from 'react';

import data from './data';

import { H2, P2 } from '../../../global/styled';
import {
  Wrapper, Cards, CardWrapper, Inner,
} from './styled';

const Philosophy = () => (
  <Wrapper>
    <H2>Our Philosophy</H2>
    <Cards>
      { data.map((card, idx) => (
        <CardWrapper key={idx}>
          <Inner>
            <img src={card.imgSrc} alt={card.imgAlt} />
            <P2>{card.desc}</P2>
          </Inner>
        </CardWrapper>
      ))}
    </Cards>
  </Wrapper>
);

export default Philosophy;
