import styled from 'styled-components';

import { InnerStyle } from '../../global/styled';

export const Wrapper = styled.div`
  max-height: 360px;
  background: #000000;
`;
export const Inner = styled(InnerStyle)`
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 15px;
    width: auto;
  }
  p {
    font-size: 20px;
    color: #fff;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    img {
      height: 10px;
    }
    p {
      font-size: 12px;
    }
  }
`;