import styled, { keyframes, css } from 'styled-components';

import { InnerStyle } from '../../global/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
  padding: 20px 0;
  border-bottom: .05px solid rgba(51, 51, 51, .3);
`;
export const Inner = styled(InnerStyle)`
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
`;
export const Outer = styled.div`
  position: absolute;
  width: calc(100vw + 18px);
  height: 100vh;
  top: 60px;
  left: 0;
  background: #fff;
  overflow: hidden;
  ${({ isCollapse }) => !isCollapse && css`
    animation: ${fadeIn} .3s ease-in;
  `}
  @media screen and (max-width: 767px) {
    top: 55px;
  }
`;
export const Menus = styled.div`
  display: ${({ isMobile }) => isMobile ? 'none' : 'flex'};
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: ${({ isMobile }) => isMobile ? 'flex' : 'none'};
    flex-direction: column;
    padding: 0 20px;
  }
`;
export const MenuTxt = styled.p`
  position: relative;
  margin: 0 15px;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  span {
    position: absolute;
    bottom: -10px;
    display: ${({ isActive }) => isActive ? 'flex' : 'none'};
    width: 100%;
    height: 3px;
    background: #333333;
  }
  @media screen and (max-width: 768px) {
    margin: 15px 0;
  }
`;
export const Logo = styled.img`
  height: 14px;
  margin: auto 0;
  @media screen and (max-width: 767px) {
    height: 10px;
  }
`;
export const Icon = styled.img`
  display: none;
  @media screen and (max-width: 768px) {
    z-index: 96;
    display: flex;
    height: 20px;
  }
  @media screen and (max-width: 767px) {
    height: 18px;
  }
`;