export default {
  title: 'Focus Sectors',
  desc: 'We specialize in businesses which focus on leveraging technology to operate and succeed on a global scale. Key competencies:',
  images: [
    {
      text: 'AI & Data Technology',
      img: {
        src: '/images/fund/img-01.png',
        alt: 'image-AI',
      }
    },
    {
      text: 'New Media',
      img: {
        src: '/images/fund/img-02.png',
        alt: 'image-media',
      }
    },
    {
      text: 'Retail Service',
      img: {
        src: '/images/fund/img-03.png',
        alt: 'image-service',
      }
    },
    {
      text: 'Mobile Content',
      img: {
        src: '/images/fund/img-04.png',
        alt: 'image-mobile',
      }
    },
    {
      text: 'Medical Tech',
      img: {
        src: '/images/fund/img-05.png',
        alt: 'image-medical',
      }
    },
    {
      text: `Sport's Tech & Service`,
      img: {
        src: '/images/fund/img-06.png',
        alt: 'image-sport',
      }
    },
  ],
  sectors: [
    {
      text: 'We look for talent within the business team.',
      img: {
        src: '/images/fund/ic-star.png',
        alt: 'ic-star',
      },
    },
    {
      text: 'Building truly differentiated products backed by leading technology.',
      img: {
        src: '/images/fund/ic-bulb.png',
        alt: 'ic-bulb',
      },
    },
    {
      text: 'In large, addressable, or fast-growing markets.',
      img: {
        src: '/images/fund/ic-chart.png',
        alt: 'ic-chart',
      },
    },
  ],
};
