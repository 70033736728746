/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import { Outer, Container, Logo, Info, Item, Icon, Close, BGGray } from './componentStyled';

const DetailComponent = ({ data, handleClose }) => (
  <>
    <BGGray onClick={handleClose} />
    <Outer>
      <Close onClick={handleClose}>X</Close>
      <Container>
        <Logo {...data.hoverImg} />
        <Info>
          <Item isInfo>
            <p>Founder: </p>
            <p>{data.details.info.founder}</p>
          </Item>
          <Item isInfo>
            <p>Invested in: </p>
            <p>{data.details.info.date}</p>
          </Item>
          <Item isInfo>
            <p>Region: </p>
            <p>{data.details.info.region}</p>
          </Item>
          <Item isInfo>
            <a href={data.details.link} target="_blank">
              <Icon src="/icons/ic-link.svg" alt="ic-link" />
              <span>Go to website</span>
            </a>
          </Item>
        </Info>
      </Container>
      <Container isIntro>
        <Item><p>{data.details.text}</p></Item>
      </Container>
    </Outer>
  </>
);

export default DetailComponent;
