import styled from 'styled-components';

import { InnerStyle } from '../../../global/styled';

export const Outer = styled.div`
  background: url('/images/fund/bg-the-fund.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
`;
export const Inner = styled(InnerStyle)`
  h1 {
    color: #fff;
    text-align: center;
  }
`;
export const StepGroup = styled.div`
  display: flex;
  flex-direction: column;
`;