import styled from 'styled-components';

import { HomeInner } from '../../../global/styled';

export const Wrapper = styled(HomeInner)`
  padding: 130px 90px 0;
  margin: auto;
  color: #fff;
  @media screen and (max-width: 767px) {
    padding: 50px 15px 0;
  }
`;
export const Inner = styled.div`
  background: #202020;
  padding: 80px 100px;
  h2 {
    text-align: left;
    padding: 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 100px;
    h2 {
      padding: 20px 0 0 0;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px 30px 40px;
  }
`;
export const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 45%;
  span {
    font-size: 36px;
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    span {
      font-size: 26px;
    }
  }
  @media screen and (max-width: 767px) {
    span {
      font-size: 20px;
    }
  }
`;
export const Vendors = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  img {
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 320px;
    img {
      width: 60px;
      height: 60px;
      margin: 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
    justify-content: space-between;
    margin-top: 20px;
    img {
      width: 80px;
      height: 80px;
      margin: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      width: 60px;
      height: 60px;
      margin: 10px;
    }
  }
`;
