import React from 'react';

import FundStep from '../../../components/FundStep';
import { PageH1, Desc } from '../../../global/styled';

import {
  Outer, Inner, StepGroup,
} from './styled';

import data from './data';

const FundContanier = () => (
  <Outer>
    <Inner>
      <PageH1>{data.title}</PageH1>
      <Desc color="white">{data.desc}</Desc>
      <StepGroup>
        { data.steps.map((step, idx) => (
          <FundStep
            key={idx}
            data={step}
            order={idx}
          />
        ))}
      </StepGroup>
    </Inner>
  </Outer>
);

export default FundContanier;
