export default {
  desc: 'We used to participated or as one of the founders in many well-known transactions in their life journey. We proudly present for average 87% of IRR for our exit projects in the past.',
  avarage: '<span>87%</span> Avg. IRR <br />for trade sale/ IPO projects',
  vendors: [
    'images/home/notable/img-logo01.png',
    'images/home/notable/img-logo02.png',
    'images/home/notable/img-logo03.png',
    'images/home/notable/img-logo04.png',
    'images/home/notable/img-logo05.png',
    'images/home/notable/img-logo06.png',
    'images/home/notable/img-logo07.png',
    'images/home/notable/img-logo08.png',
    'images/home/notable/img-logo09.png',
    'images/home/notable/img-logo10.png',
    'images/home/notable/img-logo11.png',
    'images/home/notable/img-logo12.png',
  ]
};
