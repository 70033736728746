export default {
  title: 'The Fund',
  desc: 'We built a framework that we used to work with our portfolio companies and help them to achieve KPIs across tech, product, team building and business development.',
  steps: [
    {
      title: 'Seed',
      desc: 'We prefer to lead rounds with <span>US$100K to 500K</span> for at least <span>7% target ownership</span>. We will also bring in <span>capable personal</span> or <span>strategic investors</span> to complete the business model and commercialization process.',
    },
    {
      title: 'Series A to B',
      desc: 'We prefer to lead rounds with <span>US$1M to 5M</span> for companies who finished product/service commercialization process with sizable addressable market. Other than capital, our residential experts will provide <span>aboundent corporate resource</span> for growing the company.',
    },
    {
      title: 'Growth',
      desc: 'In new deals, We prefer to invest in rounds where there is already a lead investor and the company should have <span>strategic synergies</span> with our portfolio. In follow ups, we will follow on in approximately <span>20% of the deals</span>. The strategy is to follow-on in up to <span>2 rounds</span> after the initial Orion investment.',
    },
  ],
};
