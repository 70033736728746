/* eslint-disable import/no-anonymous-default-export */
export default {
  title: 'Portfolio',
  desc: 'Fund I focuses on Tech and new economy focusing on Greater China and South East Asian region.',
  cards: [
    {
      img: {
        src: '/images/portfolio/img-inactive-logo01.png',
        alt: 'img-logo-01',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo01.png',
        alt: 'img-logo-01',
      },
      details: {
        title: 'GLIACLOUD',
        text: 'Ai tech-driven company dedicated to revolutionizing the “advertising and media” space by evolving traditional text-heavy solutions into dynamic videos at scale.',
        link: 'https://www.gliacloud.com/zh-hant/',
        info: {
          founder: 'David Chen',
          date: '2018',
          region: 'Taiwan',
          link: 'https://www.gliacloud.com/zh-hant/',
        }
      },
      color: 'transparent',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo02.png',
        alt: 'img-logo-02',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo02.png',
        alt: 'img-logo-02',
      },
      details: {
        title: 'MEDLINKER',
        link: 'https://medlinker.com/',
        text: `Provider of a social networking platform designed to connect doctors with peers, facilitate referrals, and share medical records efficiently. The company's platform connects verified doctors with health care institutions, pharmaceutical companies, patients, and insurance firms, thus providing the community with a continuous source of quality content.`,
        info: {
          founder: 'Shirui Wang',
          date: '2020-11',
          region: 'China',
          link: 'https://medlinker.com/',
        }
      },
      color: 'transparent',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo03.png',
        alt: 'img-logo-03',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo03.png',
        alt: 'img-logo-03',
      },
      details: {
        title: '林書豪-李群籃球聯盟',
        text: 'The chinese premier basketball development academy has designed a modern training curriculum based on the principles, ideals of our key partners, including jeremy lin, li-qun, and the nba training centre dongguan.',
        link: 'http://www.jlbasketball.cn/',
        info: {
          founder: 'Licheng Chang',
          date: '2020',
          region: 'China',
          link: 'http://www.jlbasketball.cn/',
        }
      },
      color: 'transparent',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo04.png',
        alt: 'img-logo-04',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo04.png',
        alt: 'img-logo-04',
      },
      details: {
        title: '體奧動力',
        text: 'Founded in 2004, csm is the pioneer in media rights operation and world feed production in china. strategically planning throughout the sports industry, with business portfolio including rights operation, feed production, marketing, data development and investments in the industry.',
        link: 'http://www.sportsmedia.com.cn/',
        info: {
          founder: 'Yidong Li',
          date: '2019',
          region: 'China',
          link: 'http://www.sportsmedia.com.cn/',
        }
      },
      color: 'red',
      bottom: 'Acq. By Sunning Sports',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo05.png',
        alt: 'img-logo-05',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo05.png',
        alt: 'img-logo-05',
      },
      details: {
        title: 'FOODTECH VENTURES',
        text: `The innovative pizza delivery system bake while delivering “not baking then delivering”. so if you're 20 minutes away from us, we prepare, bake and pack your pizza 15, 17 and 19 minutes into the journey so you get it fresh off the oven, like at home or in a gourmet restaurant!!`,
        link: 'https://mylovetriangle.pizza/',
        info: {
          founder: 'Ashutosh Mahendru',
          date: '2021-04',
          region: 'India',
          link: 'https://mylovetriangle.pizza/',
        }
      },
      color: 'transparent',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo06.png',
        alt: 'img-logo-06',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo06.png',
        alt: 'img-logo-06',
      },
      details: {
        title: 'PLAYNITRIDE INC.',
        text: 'PlayNitride is a GaN MicroLED products and solutions provider. It developed its own branded technology called “PixeLED Matrix” that comprises MicroLED epitaxial wafer production process (using Aixtron’s G5+ MOCVD systems), as well as stamp-based transfer technology and the mass repair technology. PixeLED Matrix fits into the new trends in MicroLED product development as it is a low-cost and high-yield solution.',
        link: 'https://www.playnitride.com/',
        info: {
          founder: 'Yunli Li',
          date: '2021-06',
          region: 'Taiwan',
          link: 'https://www.playnitride.com/',
        }
      },
      color: 'transparent',
    },
    {
      img: {
        src: '/images/portfolio/img-inactive-logo07.png',
        alt: 'img-logo-07',
      },
      hoverImg: {
        src: '/images/portfolio/img-logo07.png',
        alt: 'img-logo-07',
      },
      details: {
        title: 'CBH ELECTRONIC RESEARCH LTD.',
        text: 'Cloud Sky is a China Cloud computing services provider specializing in providing hosted workloads in real-time engagement video for 5G based Cloud gaming, video streaming, and other Cloud applications. The company is considered a leader in the China cloud gaming IAAS/PAAS industry, which distinguished itself by providing 5G cloud video services for the three major domestic telecommunication service providers in China.',
        link: 'http://ivcloud.net/mobile/home',
        info: {
          founder: 'Crusoe Mao',
          date: '2021-06',
          region: 'China',
          link: 'http://ivcloud.net/mobile/home',
        }
      },
      color: 'transparent',
    },
  ],
};
