export default {
  menus: [
    {
      text: 'Team',
      link: '/team',
    },
    {
      text: 'The Fund',
      link: '/fund',
    },
    {
      text: 'Portfolio',
      link: '/portfolio',
    },
    {
      text: 'Contact',
      link: 'contact',
    },
  ],
};
