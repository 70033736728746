import styled from 'styled-components';

import { InnerStyle } from '../../global/styled';

export const Wrapper = styled.div`
  background: url('/images/portfolio/bg-portfolio.png');
  background-size: cover;
  background-repeat: no-repeat;

  h1 {
    text-align: center;
    color: #fff;
  }
`;
export const Inner = styled(InnerStyle)`
`;
export const CardGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 80px auto;
  max-width: 810px;

  @media screen and (max-width: 1024px) {
    max-width: 660px;
  }
  @media screen and (max-width: 767px) {
    margin: 40px auto;
    max-width: 320px;
    justify-content: center;
  }
`;