import React, { useState, useEffect } from 'react';

import Sector from '../../../components/Sector';
import data from './data';

import { PageH1, Desc } from '../../../global/styled';

import {
  Inner, Row, Col, ImageGroup, GroupInner, SectorGroup, ImageWrapper, Group, Circle,
} from './styled';

const Focus = () => {
  const [activeId, setId] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (activeId > 4) {
        setId(0);
      } else {
        setId(activeId + 1);
      }
    }, 4000)
  }, [activeId]);

  return(
    <>
      <Inner>
        <PageH1>{data.title}</PageH1>
        <Desc>{data.desc}</Desc>
      </Inner>
      <Row>
        <Col>
          <ImageGroup>
            <GroupInner id={activeId}>
              { data.images.map((image, idx) => (
                <ImageWrapper key={idx}>
                  <img {...image.img} />
                  <p>{image.text}</p>
                </ImageWrapper>
              ))}
            </GroupInner>
          </ImageGroup>
          <Group>
            { data.images.map((circle, idx) => (
              <Circle isActive={activeId === idx} key={idx} />
            ))}
          </Group>
        </Col>
        <SectorGroup>
          { data.sectors.map((sector, idx) => (
            <Sector data={sector} key={idx} />
          ))}
        </SectorGroup>
      </Row>
    </>
  );
};

export default Focus;