import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: auto;
  img {
    width: 30%;
    max-height: 70vh;
    overflow: hidden;
  }
`;
export const TxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25vh;
  padding-bottom: 25vh;
  padding-left: 200px;
  p {
    margin: 0;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 100px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 5px;
    padding-top: 70px;
    padding-bottom: 0;
    overflow: hidden;
  }
`;