import styled, { css } from 'styled-components';

import { InnerStyle } from '../../../global/styled';

export const Wrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
`;
export const Outer = styled.div`
  background-color: #020000;
  padding-bottom: 100px;
`;
export const Inner = styled(InnerStyle)`
  h1 {
    text-align: center;
  }
`;
export const Row = styled(InnerStyle)`
  display: flex;
  justify-content: center;
  margin: 40px auto 60px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 60px;

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
`;
export const ImageGroup = styled.div`
  width: 400px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 300px;
  }
`;
export const GroupInner = styled.div`
  display: flex;
  transition: .3s;
  ${({ id }) => id && css`
    margin-left: -${ id * 400}px;
    @media screen and (max-width: 767px) {
      margin-left: -${ id * 300}px;
    }
  `}
`;
export const ImageWrapper = styled.div`
  img {
    width: 400px;
    @media screen and (max-width: 767px) {
      width: 300px;
    }
  }
  p {
    margin: 10px 0;
    font-size: 12px;
  }
`;
export const Group = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  display: flex;
  @media screen and (max-width: 1024px) {
    bottom: -15px;
  }
`;
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid;
  border-color: ${({ isActive }) => isActive ? '#707070' : '#dbdbdb'};
  border-radius: 15px;
  margin: 6px;
  background: ${({ isActive }) => isActive ? '#707070' : '#dbdbdb'};
  transition: .3s;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 6px;
    height: 6px;
    margin: 4px;
  }
`;
export const SectorGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;