import React, { useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

import { PageH1, P2 } from '../../global/styled';

import { Wrapper, TxtContainer } from './styled';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header indx={3} />
      <Wrapper>
        <img src="/images/contact/img-contact.png" alt="contact-bg" />
        <TxtContainer>
          <PageH1>Get In Touch With Us</PageH1>
          <P2>Email : info@orion-vc.com</P2>
        </TxtContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Contact;
