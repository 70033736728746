export default {
  fund: [
    {
      number: '<span>5</span> Years',
      text: 'Investment period',
    },
    {
      number: '<span>$100-500</span>K',
      text: 'Early stage',
    },
    {
      number: '<span>$1</span>M-<span>5</span>M',
      text: 'Growth stage',
    },
    {
      number: '<span>7%</span>+',
      text: 'Target ownership',
    },
    {
      number: '<span>20+</span>',
      text: 'Investments',
    },
    {
      number: 'CHINA SEA',
      text: 'Region',
    },
  ],
  statics: {
    desc: 'Since 2001, the team has invested in 43 companies in different stages,combined 72% of TMT and other industries. Total invested amount reached to US 889 million.',
    graph: '/images/home/statics/img-barchart.png',
  },
};
