import React, { useState, useEffect } from 'react';

import TeamCard from '../../../components/TeamCard';
import data from '../../Team/data';
import Popup from '../../../components/TeamModal';

import { H2 } from '../../../global/styled';
import { Wrapper, CardGroup } from './styled';

const Team = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeId, setId] = useState(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (id) => {
    setId(id);
    setIsVisible(true);
  };
  const handleClose = () => {
    setId(null);
    setIsVisible(false);
  };

  return (
    <>
      <Wrapper>
        <H2>Team</H2>
        <CardGroup>
          { data.cards.map((card, idx) => (
            <TeamCard
              data={card}
              key={idx}
              handleClick={() => handleClick(idx)}
            />
          ))}
        </CardGroup>
      </Wrapper>
      { isVisible && <Popup data={data.cards[activeId]} handleClose={handleClose} />}
    </>
  );
};

export default Team;
