import React from 'react';

import Card from './card';

import { H2 } from '../../global/styled';
import { Wrapper, Inner } from './styled';
import data from './data';

const News = () => {
  const handleClick = (link) => {
    window.open(link, '_blank');
  };
  return (
    <Wrapper>
      <H2>News</H2>
      <Inner>
        { data.map((card, idx) => (
          <Card
            data={card}
            key={idx}
            handleClick={handleClick}
          />
        ))}
      </Inner>
    </Wrapper>
  );
}

export default News;
