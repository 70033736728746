import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Outer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  animation: ${fadeIn} ease .3s;
`;
export const CloseIcon = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  transition: .2s;
  :hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    left: 15px;
    right: unset;
    :hover {
      transform: none;
    }
  }
`;
export const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(64, 64, 64, .5);
`;
export const Inner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1024px;
  max-height: 600px;
  z-index: 99;
  background: #404040;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  @media screen and (max-width: 1024px) {
    max-height: 500px;
  }
  @media screen and (max-width: 767px) {
    max-height: unset;
    border-radius: 0;
    overflow: scroll;
    align-items: center;
    padding: 50px 0 100px;
  }
`;
export const Upper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  color: #fff;
  padding: 20px 10px;
  p {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
  }
  span {
    font-size: 26px;
    color: #F3D125;
  }
  @media screen and (max-width: 1024px) {
    p {
      font-size: 26px;
    }
    span {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    margin-bottom: 30px;
    p {
      text-align: center;
    }
    span {
      text-align: center;
    }
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: 300px;
    margin-bottom: 30px;
  }
`;
export const Left = styled.div`
  img {
    width: auto;
    height: 390px;
  }
  @media screen and (max-width: 1024px) {
    img {
      width: auto;
      height: 300px;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      width: 300px;
      height: auto;
      margin: 20px auto;
    }
  }
`;
export const Right = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  min-height: 310px;
  padding: 20px 20px 40px;
  p {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: .5px solid #404040;
    font-size: 20px;
  }
  li {
    color: #404040;
    font-size: 16px;
    margin: 5px 0;
  }
  @media screen and (max-width: 1024px) {
    p {
      font-size: 14px;
    }
    li {
      z-index: 9;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    margin: auto 0;
  }
`;