import styled, { css } from 'styled-components';

export const Outer = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  img {
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }
  p {
    font-size: 18px;
    color: #404040;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 10px 20px;
  }
`;