import React from 'react';

import Notable from './Notable';
import Team from './Team';

import { Wrapper } from './styled';

const MidBanner = () => (
  <Wrapper>
    <Notable />
    <Team />
  </Wrapper>
);

export default MidBanner;
