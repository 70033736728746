import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 1024px;
  margin: auto;
  background-color: #fff;
  :nth-child(2) {
    margin: 60px auto;
  }
  @media screen and (max-width: 1440px) {
    min-width: 800px;
  }
  @media screen and (max-width: 1024px) {
    min-width: 738px;
  }
  @media screen and (max-width: 767px) {
    min-width: unset;
    max-width: 230px;
  }
`;
export const Inner = styled.div`
  padding: 50px 120px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1440px) {
    padding: 15px 30px 25px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: bold;
  }
`;
export const Order = styled.div`
  color: #fff;
  background-color: #757575;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;
export const Right = styled.div`
  max-width: 530px;
  font-size: 20px;

  @media screen and (max-width: 1024px) {
    max-width: 485px;
  }

  p {
    span {
      color: #96920e;
      font-weight: bold;
    }
  }
`;
