import React from 'react';
import styled, { css } from 'styled-components';

import { PageP1 } from '../../global/styled';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 50px;
  p {
    font-weight: bold;
    margin: 8px 0;
  }
  span {
    font-size: 18px;
  }
  img {
    width: 270px;
    transition: .3s;
    cursor: pointer;
  }

  @media screen and (max-width: 1440px) {
    p {
      margin: 4px 0;
    }
    span {
      font-size: 12px;
    }
  }

  ${({ page }) => page === 'team' && css`
    margin: 30px auto;
    img {
      width: 210px;
      transition: .3s;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.05);
    }
    @media screen and (max-width: 767px) {
      img:hover {
        transform: none;
      }
    }
  `}
`;

const TeamCard = ({ data, page, handleClick }) => (
  <Card page={page} onClick={handleClick}>
    <img {...data.img} />
    <PageP1 >{data.name}</PageP1>
    <span>{data.job}</span>
  </Card>
);

export default TeamCard;
