import React from 'react';

import { HomeP1, P2 } from '../../global/styled';
import { CardWrapper, CardInner, Link } from './styled';

const Card = ({ data, handleClick }) => (
  <CardWrapper onClick={() => handleClick(data.link)}>
    <CardInner>
      <HomeP1>{data.date}</HomeP1>
      <P2>{data.desc}</P2>
      <Link>Read More</Link>
    </CardInner>
  </CardWrapper>
);

export default Card;
