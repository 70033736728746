import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './index.css';
import Home from './containers/Home';
import Team from './containers/Team';
import Fund from './containers/Fund';
import Portfolio from './containers/Portfolio';
import Contact from './containers/Contact';
import reportWebVitals from './reportWebVitals';

function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/team" component={Team} />
        <Route path="/fund" component={Fund} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/contact" component={Contact} />
      </Switch>
    </>
  );
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
