import styled, { css } from 'styled-components';

export const HomeH1 = styled.h1`
  font-size: 48px;
  letter-spacing: 1.4px;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    letter-spacing: .8px;
  }
`;
export const PageH1 = styled.h1`
  font-size: 36px;
  text-align: center;
  margin: 0 auto;
  padding: 40px 0 10px;
  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
`;
export const Desc = styled.p`
  font-size: 22px;
  max-width: 530px;
  line-height: 30px;
  margin: 20px auto;
  letter-spacing: 1px;
  @media screen and (max-width: 1024px) {
    letter-spacing: .5px;
    font-size: 16px;
    line-height: 20px;
  }
  ${({ color }) => color && css`
    color: ${color};
  `}
`;
export const H2 = styled.h2`
  font-size: 36px;
  text-align: center;
  padding: 80px 0;
  margin: 0;
  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
    font-size: 22px;
  }
`;
export const HomeP1 = styled.p`
  font-size: 26px;
  letter-spacing: 1px;
  line-height: 36px;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    letter-spacing: unset;
    line-height: 20px;
    letter-spacing: .5px;
  }
`;
export const PageP1 = styled.p`
  font-size: 22px;
  letter-spacing: .8px;
  line-height: 30px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    letter-spacing: .5px;
  }
`;
export const P2 = styled.p`
  font-size: 20px;
  letter-spacing: .6px;
  line-height: 30px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .3px;
  }
`;
export const P3 = styled.p`
  font-size: 18px;
  line-height: 20px;
  letter-spacing: .4px;
  @media screen and (max-width: 1440px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .3px;
  }
`;
export const Span = styled.span`
  font-size: 16px;
  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
`;
export const InnerStyle = styled.div`
  max-width: 1280px;
  margin: auto;
  @media screen and (max-width: 1440px) {
    max-width: 1024px;
    padding: 0 15px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    padding: 0 20px;
  }
`;
export const HomeInner = styled.div`
  max-width: 1200px;
  margin: auto;
  @media screen and (max-width: 1440px) {
    max-width: 1024px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 767px) {
    max-width: 320px;
    padding: 0 20px;
  }
`;
