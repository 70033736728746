import React from 'react';

import {
  Wrapper, Inner, Left, Order, Right,
} from './styled';

import { HomeP1, P2 } from '../../global/styled';

const FundStep = ({ data, order }) => (
  <Wrapper>
    <Inner>
      <Left>
        <Order>{order + 1}</Order>
        <HomeP1>{data.title}</HomeP1>
      </Left>
      <Right>
        <P2 dangerouslySetInnerHTML={{ __html: data.desc }} />
      </Right>
    </Inner>
  </Wrapper>
);

export default FundStep;
