import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import MobileMenu from './MobileMenu';
import data from './data';
import {
  Wrapper, Inner, Logo, Menus, MenuTxt, Icon,
} from './styled';

const Header = ({ indx }) => {
  const [activeId, setId] = useState(indx);
  const [isCollapse, setCollapse] = useState(true);

  useEffect(() => {
    if (indx) {
      setId(indx);
    }
  }, []);

  useEffect(() => {
    if (!isCollapse) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [isCollapse]);

  const handClick = (id) => {
    setId(id);
  };
  const handleMouseLeave = (id) => {
    if (id !== indx) {
      setId(indx);
    }
  };
  const handleCollapse = () => {
    setCollapse(!isCollapse);
  };

  return (
    <Wrapper>
      <Inner>
        <Link to="/">
          <Logo src="/images/logo.png" alt="logo" />
        </Link>
        <Menus>
        { data.menus.map((menu, idx) => (
          <Link
            key={idx}
            to={menu.link}
            onMouseOver={() => handClick(idx)}
            onMouseLeave={() => handleMouseLeave(idx)}
            onClick={() => handClick(idx)}
          >
            <MenuTxt isActive={idx === activeId}>
              {menu.text}
              <span />
            </MenuTxt>
          </Link>
        ))}
        </Menus>
        { !isCollapse
          && (
          <MobileMenu
            isCollapse={isCollapse}
            activeId={activeId}
            handClick={handClick}
          />
        )}
        <Icon
          src={isCollapse ? '/icons/ic-hamburger.png' : '/icons/ic-cross-header.png'}
          alt={isCollapse ? 'ic-hamburger' : 'ic-cross'}
          onClick={handleCollapse}
        />
      </Inner>
    </Wrapper>
  );
};

export default Header;
