import styled from 'styled-components';

import { HomeInner } from '../../global/styled';

export const Wrapper = styled.div`
  background: url('/images/home/news-bg.png');
  h2 {
    color: #fff;
    margin: 0;
  }
  padding-bottom: 50px;
`;
export const Inner = styled(HomeInner)`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: auto;
`;
export const CardWrapper = styled.div`
  background-color: rgba(256, 256, 256, 0.9);
  border-radius: 5px;
  :nth-child(n-2) {
    margin-bottom: 40px; 
  }
  cursor: pointer;
  transition: .3s;
  :hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 1024px) {
    :hover {
      transform: none;
    }
  }
`;
export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  p {
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 30px;
  }
`;
export const Link = styled.div`
  margin-top: 20px;
  font-size: 16px;
  transition: .3s;

  @media screen and (max-width: 1024px) {
    margin-top: 10px;
    font-size: 14px;
  }

  :hover {
    color: #757575;
  }
`;
