import React, { useEffect, useState } from 'react';

import Header from '../Header';
import PortfolioCard from '../../components/PortfolioCard';
import Footer from '../Footer';

import { useWindowSize } from '../../hooks/useWindowSize';

import data from './data';

import { PageH1, Desc } from '../../global/styled';
import {
  Wrapper, Inner, CardGroup,
} from './styled';

const Portfolio = () => {
  const [isHover, setHover] = useState(false);
  const [activeId, setId] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (width < 1025) setIsMobile(true);
    else setIsMobile(false);
  }, [width]);

  const handleHover = (idx) => {
    setHover(true);
    setId(idx);
  };
  const handleLeave = () => {
    setHover(false);
    setId(null);
  };

  return (
    <Wrapper>
      <Header indx={2} />
      <Inner>
        <PageH1>{data.title}</PageH1>
        <Desc color="white">{data.desc}</Desc>
        <CardGroup onMouseLeave={() => handleLeave()}>
          { data.cards.map((card, idx) => (
            <PortfolioCard
              data={card}
              key={idx}
              isMobile={isMobile}
              isHover={isHover}
              isActive={activeId === idx}
              handleHover={() => handleHover(idx)}
              handleLeave={() => handleLeave()}
            />
          ))}
        </CardGroup>
      </Inner>
      <Footer />
    </Wrapper>
  );
};

export default Portfolio;
