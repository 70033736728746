export default {
  desc: 'The management team collectively accumulates over 20 years of entrepreneurial and investment experience.',
  cards: [
    {
      img: {
        src: '/images/home/team/team-01.png',
        alt: 'img-01',
      },
      imgActive: {
        src: '/images/team/team-active-01.png',
        alt: 'img-01',
      },
      name: 'LC Chang',
      job: 'Managing Partner',
      desc: 'LC is a visionary entrepreneur with more than 30 years of experience building businesses in Advertisement, New media, Internet marketing, and Sports.',
      lists: [
        'Founder of China Sports Media',
        'Former Global COO & CMO of Sina.net',
        'GM of Grey International Group',
        'Executive MBA from the Management College of National Taiwan University',
      ],
    },
    {
      img: {
        src: '/images/home/team/team-02.png',
        alt: 'img-02',
      },
      imgActive: {
        src: '/images/team/team-active-02.png',
        alt: 'img-02',
      },
      name: 'Kurt Chen',
      job: 'Managing Partner',
      desc: 'Kurt has 20 years of experience in investment banking, direct investment, and venture operations. He is a versatile professional in business strategy and different investment stage management.',
      lists: [
        'Former Partner of SEEDs of Innovation',
        'VP of Lehman Brothers & Nomura Asia',
        'Asset & Liability Management of SMBC',
        'Business Controlling analyst of Deutsche Bank',
        'MBA from London Business School',
      ],
    },
    {
      img: {
        src: '/images/home/team/team-03.png',
        alt: 'img-03',
      },
      imgActive: {
        src: '/images/team/team-active-03.png',
        alt: 'img-03',
      },
      name: 'Vicky Zheng',
      job: 'Managing Partner',
      desc: 'Vicky has close to 15 years of experience in VC/PE investments, secondary investments, and accounting and auditing. She owns a solid track record in the TMT investment sector and participates in various industry benchmark deals in China.',
      lists: [
        'Associate Director of BOCOM',
        'Investment Director of Global TMT of Fosun Group',
        'Senior Associate of Asia Pacific Capital',
        'Senior Associate of PricewaterhouseCoopers',
        'BA Degree from Hong Kong’s City University',
        'Certified FCCA and CFA',
      ],
    },
    {
      img: {
        src: '/images/home/team/team-04.png',
        alt: 'img-04',
      },
      imgActive: {
        src: '/images/team/team-active-04.png',
        alt: 'img-04',
      },
      name: 'Jason Chang',
      job: 'Investment Associate',
      desc: 'Jason joined Orion Venture Capital as an Associate in 2019, where he is closely involved in deal sourcing, execution of investment opportunities, and post-investment management, primarily focusing on TMT, Data & AI, and the sports industry.',
      lists: [
        'Investment Associate of Orion Venture Partners',
        'Business Analyst Intern of New Future Capital',
        'Admin Secretary to Chairman, Enjoytown Co.',
        'Master of Business and Commerce, Western Sydney University',
      ],
    },
    {
      img: {
        src: '/images/home/team/team-05.png',
        alt: 'img-05',
      },
      imgActive: {
        src: '/images/team/team-active-05.png',
        alt: 'img-05',
      },
      name: 'Leroy Lau',
      job: 'Venture Partner',
      desc: 'Leroy invests in purpose-driven businesses under the initiatives of 43 Ventures and Everiii. Previously, he was the lead advisory partner at EY Taiwan and held various leadership positions at the EY Greater China Advisory Practice.',
      lists: [
        'Managing Director of Everiii Capital and 43 Ventures',
        'Advisory Partner of EY Greater China',
        'Bachelor of Computer Engineering and Laws, New South Wales Australlia',
        'Doctor of Education, Northeastern University',
        'Fellow member of the Hong Kong Institute of Certified Public Accountants and CPA Australia.',
      ],
    },
  ],
};
