import styled from 'styled-components';

import { HomeInner } from '../../../global/styled';

export const Wrapper = styled(HomeInner)`
`;
export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const CardWrapper = styled.div`
  background: #636363;
  max-width: 336px;
  @media screen and (max-width: 1440px) {
    max-width: 300px;
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
    :nth-child(2) {
      margin: 30px auto;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 260px;
    margin: auto;
  }
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
  img {
    width: auto;
    height: 90px;
    margin-bottom: 20px;
  }
  p {
    font-weight: lighter;
    color: #fff;
  }
  @media screen and (max-width: 1440px) {
    img {
      width: auto;
      height: 60px;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 30px;
    img {
      margin: 0;
    }
    p {
      max-width: 85%;
      line-height: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 60px 10px;
    img {
      margin-bottom: 10px;
    }
    p {
      line-height: 20px;
    }
  }
`;