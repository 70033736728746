import styled from 'styled-components';

import { InnerStyle } from '../../global/styled';

export const Wrapper = styled(InnerStyle)`
  h1 {
    text-align: center;
  }
`;
export const CardGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px auto 80px;
  @media screen and (max-width: 1440px) {
    max-width: 768px;
  }
`;