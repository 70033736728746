import React from 'react';

import {
  Outer, CloseIcon, Background, Inner, Upper, Bottom, Left, Right,
} from './styled';

const Popup = ({ data, handleClose }) => {
  return (
    <Outer>
      <Background onClick={handleClose} />
      <Inner>
        <CloseIcon
          src="/icons/ic-close.png"
          alt="ic-close"
          onClick={handleClose}
        />
        <Upper>
          <p>{data.name}</p>
          <span>{data.job}</span>
        </Upper>
        <Bottom>
          <Left>
            <img {...data.imgActive} />
          </Left>
          <Right>
            <p>{data.desc}</p>
            { data.lists && data.lists.map((list, idx) => (
              <li key={idx}>{list}</li>
            ))}
          </Right>
        </Bottom>
      </Inner>
    </Outer>
  );
};

export default Popup;
