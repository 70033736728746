import React, { useEffect } from 'react';

import Header from '../Header';
import Focus from './Focus';
import FundContainer from './Container';
import Footer from '../Footer';

const Fund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header indx={1} />
      <FundContainer />
      <Focus />
      <Footer />
    </>
  );
};

export default Fund;
