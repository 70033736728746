import React, { useState, useEffect } from 'react';

import Header from '../Header';
import TeamCard from '../../components/TeamCard';
import Footer from '../Footer';
import Popup from '../../components/TeamModal';
import data from './data';

import { PageH1, Desc } from '../../global/styled';
import { Wrapper, CardGroup } from './styled';

const Team = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeId, setId] = useState(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (id) => {
    setId(id);
    setIsVisible(true);
  };
  const handleClose = () => {
    setId(null);
    setIsVisible(false);
  };

  return (
    <>
      <Header indx={0} />
      <Wrapper>
        <PageH1>Team</PageH1>
        <Desc>{data.desc}</Desc>
        <CardGroup>
          { data.cards.map((card, idx) => (
            <TeamCard
              data={card}
              key={idx}
              page="team"
              handleClick={() => handleClick(idx)}
            />
          ))}
        </CardGroup>
      </Wrapper>
      <Footer />
      { isVisible && <Popup data={data.cards[activeId]} handleClose={handleClose} />}
    </>
  );
};

export default Team;
