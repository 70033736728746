/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import DetailComponent from './Component';
import { Wrapper, Outer, Blank, Logo } from './styled';

const PortfolioCard = ({ data, isMobile, isActive, handleHover, handleLeave }) => (
  <Wrapper>
    <Outer
      onClick={() => handleHover()}
      onMouseOver={() => isMobile ? null : handleHover()}
    >
      {isActive || isMobile ? <Logo {...data.hoverImg} /> : <Logo {...data.img} />}
      {(isActive && !isMobile) && <DetailComponent data={data} handleClose={handleLeave} />}
    </Outer>
    <Blank color={data.color}>{data?.bottom}</Blank>
    {(isActive && isMobile) && <DetailComponent data={data} handleClose={handleLeave} />}
  </Wrapper>
);

export default PortfolioCard;
